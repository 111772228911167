/*just bg and body style*/
.container{
    background-color:#51445f;
    padding-bottom:20px;
    margin-top:10px;
    border-radius:5px;
    /* padding-top: 5px; */
    }
    .center{
    text-align:center;  
    }
    #top{
    margin-top:20px;  
    }
    .btn-container{
    background:#fff;
    border-radius:5px;
    padding-bottom:50px;
    margin-bottom:20px;
    padding-top: 5px;
    }
    .white{
    color:white;
    }
    .imgupload{
    color:#1E2832;
    padding-top:40px;
    font-size:7em;
    }
    #namefile{
    color:black;
    }
    h4>strong{
    color:#ff3f3f
    }
    /* .btn-primary{
    border-color: #ff3f3f !important;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #ff3f3f !important;
    border-color: #ff3f3f !important;
    } */
    
    /*these two are set to not display at start*/
    .imgupload.ok{
    display:none;
    color:green;
    }
    .imgupload.stop{
    display:none;
    color:red;
    }
    
    
    /*this sets the actual file input to overlay our button*/ 
    #fileup{
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    width:200px;
    cursor: pointer;
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    height: 50px;
    }
    
    /*switch between input and not active input*/
    #submitbtn{
      padding:5px 50px;
      display:none;
    }
    #fakebtn{
      padding:5px 40px;
    }
    
    
    /*www.emilianocostanzo.com*/
    #sign{
        color:#1E2832;
        position:fixed;
        right:10px;
        bottom:10px;
        text-shadow:0px 0px 0px #1E2832;
        transition:all.3s;
    }
    #sign:hover{
        color:#1E2832;
        text-shadow:0px 0px 5px #1E2832;
    }

    /* .title {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #999;
        text-align: center;
    }
    
    h1 {
        font-size: 16px;
        font-weight: 300;
        color: #666;
    }
     */
    .dropzone {
        /* width: 100px;
        height: 80px; */
        border: 1px dashed #999;
        border-radius: 3px;
        text-align: center;
        margin: 30px;
    padding-top: 10px;
    }
    
    .upload-icon {
        margin: 25px 2px 2px 2px;
    }
    
    .upload-input {
        position: relative;
        top: -62px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .reactEasyCrop_Container{
        margin: 0px 7px 0px 20px;
        border-radius: 6%;
    }

    .homecmp-container .webcam-container
    {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:10px 0;
    }
    .homecmp-container .webcam-container .webcam-btn
    {
    margin-top: 10px;
    width:30%;
    }