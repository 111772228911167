.myimg{
  width:100%; background-color: #ccc; height: 120px;
  background-image: url('/images/page-img/profile-bg.jpg');
}

.mtop600{
  margin-top: 600px;
}
.gap{
  min-height: 600px;
}

.font40{
  font-size: 40px;
}
.padLRTzero{
  padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.bg-themeprimary {
  background-color: #51445f!important;
}

.bg-white {
  background-color: #e6ebf1de!important;
}

.bg-themesecondary {
  background-color: #fb6e52!important;
}
.bg-themefourthcolor {
  background-color: #a0d468!important;
}

.table thead th {
  
  border-bottom: 2px solid #12171d;
  background: #51445f;
  color: #fff;
}
.badge-pill{
    padding-right: 1.6em;
    padding-left: 1.6em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}