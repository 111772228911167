.react-datepicker-wrapper{
    display: unset !important;
}

.leave_div .row{
    margin-right: -23px;
    margin-left: -23px;
}

.list-group-item {
    border:unset !important;
}
.ul{
    text-decoration-line: underline;
}
.holiday .iq-card .iq-card-header {
    min-height: 75px !important;
}

.list-group-item {
    background-color:#ccc; margin-right: 3px; height: 35px;  color: #fff; padding:3px 8px 7px 8px; margin-top: 10px; 
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;  
}
.ulbg{
 background-color: #212529;
}
.list-group-item a{
    padding-top: 1px; color: #fff; 
}

.tbl{
    display: inline-table !important;
}
.reactEasyCrop_Container{
    position: relative !important;
}
.reactEasyCrop_Container > img{
    position: unset !important;
}

.homecmp-container .webcam-container
{
display: flex;
flex-direction: column;
text-align: center;
padding:10px 0;
}
.homecmp-container .webcam-container .webcam-btn
{
margin-top: 10px;
width:30%;
}