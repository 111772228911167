.clr{
    color: #ffa500;
}

.p_t_10{
    padding-top:1px;
}

.p_t_5{
    padding-top:1px;
    padding-bottom:1px;
}
.p_t1_5{
    padding-top:5px;
}
.p_t_30{
    padding-top: 30px;
}

.p_t_45_l_35{
    padding-top: 45px;
    padding-left: 35%;
}
.head{
    background-color: #ffa500;
    color:white;
    margin-left: 10px;
    margin-right: 10px;
}

.head1{
    color:#ffff !important;padding: 10px;margin-bottom:unset !important;
}

.text_align{
    text-align: right;
}
.text_align_center{
  text-align: center;
}
.p_t_50{
    padding-top: 50px;
}

.p_t1_8{
    padding-top:8px;
}

.hd{
    color:#000 !important;padding: 10px;margin-bottom:unset !important;
}

.p_t_15{
    padding-top: 15px;
}

.hd2{
    color:#9458ca;font-weight: bold 
}

.hd3{
    background-color: #ffa500;color:white;margin-left: 10px;margin-right: 10px;
}

.hd4{
    background-color: #64ab6c;color:white;margin-left: 10px;margin-right: 10px;
}

.hd5{
    background-color: #d375df;;color:white;margin-left: 10px;margin-right: 10px;
}

.p_t_20{
    padding-left: 20px;padding: 5px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #deb1e9 !important;
  }
  .form-control {
    border-bottom: 1px dotted !important;
    background:unset !important;
  }
  .p4{
      padding-right: 35px !important;
      padding-left: 35px !important;
  }
  .pb{
    padding-bottom: 40px !important;
  }
  .iq-footer {
        margin-left: unset !important;
  }

  .p_r{
    padding-right: 1px !important;
  }
  .p_r_0{
    padding-right: 0px!important;
  }

  .bk{
    background-color: #f7e2e9;
  }
  .bk1{
    background-color: antiquewhite;
  }

  .bk2{
    background-color: aliceblue;
  }

  .bk3{
    background-color: #cce9cc;
  }
  .text_center{
    text-align: center;
  }

  .text_end{
    text-align: end;
  }

  .p_l_1{
    padding-left: 1px !important;
  }

  .p_l_0{
    padding-left: 1px !important;
  }

  .border_0{
    border: 0px solid #ced4da !important;
    border-bottom: 1px dotted !important;
  }





  .databox-container {
    text-align: center;
    font-size: 0
  }
  .databox {
    display: inline-block;
    width: 100%;
    height: 65px;
    padding: 0;
    font-size: 0;
    margin-bottom: 30px;
    vertical-align: top;
    min-width: 130px
  }
  .databox .databox-icon {
    margin: 0;
    display: inline-block;
    width: 100%;
    text-align: center
  }
  .databox .databox-icon>.fa, .databox .databox-icon .typcn, .databox .databox-icon .glyphicon, .databox .databox-icon .wi {
    display: inline-block;
    margin: 0;
    text-align: center;
    position: relative
  }
  .databox .databox-icon>.fa:before, .databox .databox-icon .typcn:before, .databox .databox-icon .glyphicon:before, .databox .databox-icon .wi:before {
    font-size: 40px;
    display: block;
    text-align: center
  }
  .databox .databox-sparkline {
    padding-top: 0;
    margin: 0 auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    text-align: center;
    font-size: 24px
  }
  .databox .databox-piechart {
    padding-top: 0;
    margin: 0 auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 300
  }
  .databox .databox-header {
    display: block;
    font-size: 18px;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-weight: 400;
    margin: 8px 5px;
    position: relative
  }
  .databox .databox-header i {
    margin-right: 5px
  }
  .databox .databox-number {
    display: block;
    font-size: 17px;
    line-height: 26px;
    margin: 2px;
    position: relative;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-weight: 700
  }
  .databox .databox-number i {
    margin-right: 5px
  }
  .databox .databox-number.number-xs {
    font-size: 11px
  }
  .databox .databox-number.number-sm {
    font-size: 14px
  }
  .databox .databox-number.number-lg {
    font-size: 20px
  }
  .databox .databox-number.number-xlg {
    font-size: 23px
  }
  .databox .databox-number.number-xxlg {
    font-size: 30px
  }
  .databox .databox-title {
    display: block;
    font-size: 16px;
    font-weight: 300;
    margin: 2px;
    position: relative
  }
  .databox .databox-title i {
    margin-right: 5px;
    font-size: 13px
  }
  .databox .databox-text {
    display: block;
    font-size: 11px;
    margin: 4px 0 2px;
    position: relative
  }
  .databox .databox-text i {
    margin-right: 5px;
    font-size: 13px
  }
  .databox .databox-inlinetext {
    font-size: 11px;
    margin: 2px;
    position: relative
  }
  .databox .databox-inlinetext i {
    margin-right: 5px;
    font-size: 13px
  }
  .databox .databox-stat {
    display: inline-block;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 2px 5px
  }
  .databox .databox-stat.stat-left {
    left: 7px;
    right: auto
  }
  .databox .databox-stat .stat-text {
    display: inline-block;
    font-size: 13px;
    padding-right: 5px;
    font-weight: 500
  }
  .databox .databox-stat .stat-icon {
    display: inline-block;
    font-size: 13px
  }
  .databox .databox-stat .stat-icon.icon-lg {
    font-size: 16px
  }
  .databox .databox-stat .stat-icon.icon-xlg {
    font-size: 18px
  }
  .databox .databox-stat.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
  }
  .databox .databox-stat[class*="bg-"] {
    color: #fff
  }
  .databox .databox-state {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 24px;
    width: 24px;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    background-color: #e5e5e5;
    text-align: center;
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,.2);
    -moz-box-shadow: 0 0 3px rgba(0,0,0,.2);
    box-shadow: 0 0 3px rgba(0,0,0,.2);
    border: 2px solid #fff
  }
  .databox .databox-state i {
    font-size: 13px;
    color: #fff;
    line-height: 20px
  }
  .databox .databox-row {
    width: 100%;
    margin: 0;
    position: relative
  }
  .databox .databox-row[class*="bg-"] {
    color: #fff
  }
  .databox .databox-row.bordered-bottom {
    border-bottom: 1px solid #fff
  }
  .databox .databox-row.bordered-top {
    border-top: 1px solid #fff
  }
  .databox .databox-row.bordered-left {
    border-left: 1px solid #fff
  }
  .databox .databox-row.bordered-right {
    border-right: 1px solid #fff
  }
  .databox .databox-row.bordered-thick {
    border-width: 2px
  }
  .databox .databox-row.row-1 {
    height: 8.33%
  }
  .databox .databox-row.row-2 {
    height: 16.66%
  }
  .databox .databox-row.row-3 {
    height: 25%
  }
  .databox .databox-row.row-4 {
    height: 33.33%
  }
  .databox .databox-row.row-5 {
    height: 41.66%
  }
  .databox .databox-row.row-6 {
    height: 50%
  }
  .databox .databox-row.row-7 {
    height: 58.33%
  }
  .databox .databox-row.row-8 {
    height: 66.66%
  }
  .databox .databox-row.row-9 {
    height: 75%
  }
  .databox .databox-row.row-10 {
    height: 83.33%
  }
  .databox .databox-row.row-11 {
    height: 91.66%
  }
  .databox .databox-row.row-12 {
    height: 100%
  }
  .databox .databox-cell {
    height: 100%;
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px
  }
  .databox .databox-cell[class*="bg-"] {
    color: #fff
  }
  .databox .databox-cell.bordered-bottom {
    border-bottom: 1px solid #fff
  }
  .databox .databox-cell.bordered-top {
    border-top: 1px solid #fff
  }
  .databox .databox-cell.bordered-left {
    border-left: 1px solid #fff
  }
  .databox .databox-cell.bordered-right {
    border-right: 1px solid #fff
  }
  .databox .databox-cell.bordered-thick {
    border-width: 2px
  }
  .databox .databox-cell.cell-1 {
    width: 8.33%
  }
  .databox .databox-cell.cell-2 {
    width: 16.66%
  }
  .databox .databox-cell.cell-3 {
    width: 25%
  }
  .databox .databox-cell.cell-4 {
    width: 33.33%
  }
  .databox .databox-cell.cell-5 {
    width: 41.66%
  }
  .databox .databox-cell.cell-6 {
    width: 50%
  }
  .databox .databox-cell.cell-7 {
    width: 58.33%
  }
  .databox .databox-cell.cell-8 {
    width: 66.66%
  }
  .databox .databox-cell.cell-9 {
    width: 75%
  }
  .databox .databox-cell.cell-10 {
    width: 83.33%
  }
  .databox .databox-cell.cell-11 {
    width: 91.66%
  }
  .databox .databox-cell.cell-12 {
    width: 100%
  }
  .databox.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: calc(100% - 65px)
  }
  .databox.databox-inverted .databox-right {
    width: 65px
  }
  .databox.databox-transparent .databox-left {
    background-color: transparent!important
  }
  .databox.databox-transparent .databox-right {
    background-color: transparent!important
  }
  .databox .databox-left {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0;
    width: 65px;
    height: 65px;
    padding: 10px;
    color: #51445f
  }
  .databox .databox-left[class*="bg-"] {
    color: #fff
  }
  .databox .databox-right {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    -lh-property: 0;
    width: -webkit-calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: calc(100% - 65px);
    height: 65px;
    color: #555;
    padding: 5px 10px
  }
  .databox .databox-right.bordered {
    border-left: 1px solid #fff
  }
  .databox .databox-right.bordered-thick {
    border-left: 3px solid #fff
  }
  .databox .databox-right[class*="bg-"] {
    color: #fff
  }
  .databox.databox-shadowed {
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,.2);
    -moz-box-shadow: 0 0 3px rgba(0,0,0,.2);
    box-shadow: 0 0 3px rgba(0,0,0,.2)
  }
  .databox.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-left {
    -webkit-border-radius: 3px 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 3px;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-right {
    -webkit-border-radius: 0 3px 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 3px 0;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-right .databox-row:first-child {
    -webkit-border-radius: 0 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 0 0;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-right .databox-row:last-child {
    -webkit-border-radius: 0 0 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 0;
    background-clip: padding-box
  }
  .databox.databox-graded {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNkZGRkZGQiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(left, #ddd, #fff);
    background-image: -moz-linear-gradient(left, #ddd, #fff);
    background-image: -o-linear-gradient(left, #ddd, #fff);
    background-image: linear-gradient(to right, #ddd, #fff)
  }
  .databox.databox-halved .databox-left {
    width: 50%!important
  }
  .databox.databox-halved .databox-right {
    width: 50%!important
  }
  .databox.databox-lg {
    height: 80px
  }
  .databox.databox-lg .databox-icon>.fa:before, .databox.databox-lg .databox-icon .typcn:before, .databox.databox-lg .databox-icon .glyphicon:before, .databox.databox-lg .databox-icon .wi:before {
    font-size: 48px;
    padding: 6px 4px 7px
  }
  .databox.databox-lg .databox-sparkline {
    margin: 13px auto
  }
  .databox.databox-lg .databox-number {
    font-size: 20px;
    margin: 4px 0 6px
  }
  .databox.databox-lg .databox-left {
    width: 80px;
    height: 80px
  }
  .databox.databox-lg .databox-right {
    -lh-property: 0;
    width: -webkit-calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: calc(100% - 80px);
    height: 80px;
    padding: 10px 15px
  }
  .databox.databox-lg.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: calc(100% - 80px)
  }
  .databox.databox-lg.databox-inverted .databox-right {
    width: 80px
  }
  .databox.databox-xlg {
    height: 170px
  }
  .databox.databox-xlg .databox-icon>.fa:before, .databox.databox-xlg .databox-icon .typcn:before, .databox.databox-xlg .databox-icon .glyphicon:before {
    padding: 6px 4px 7px
  }
  .databox.databox-xlg .databox-icon .wi:before {
    font-size: 55px!important
  }
  .databox.databox-xlg .databox-left {
    width: 170px;
    height: 170px
  }
  .databox.databox-xlg .databox-right {
    -lh-property: 0;
    width: -webkit-calc(100% - 170px);
    width: -moz-calc(100% - 170px);
    width: calc(100% - 170px);
    height: 170px
  }
  .databox.databox-xlg.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 170px);
    width: -moz-calc(100% - 170px);
    width: calc(100% - 170px)
  }
  .databox.databox-xlg.databox-inverted .databox-right {
    width: 170px
  }
  .databox.databox-xxlg {
    height: 250px
  }
  .databox.databox-xxlg .databox-icon>.fa:before, .databox.databox-xxlg .databox-icon .typcn:before, .databox.databox-xxlg .databox-icon .glyphicon:before, .databox.databox-xxlg .databox-icon .wi:before {
    font-size: 200px;
    padding: 6px 4px 7px
  }
  .databox.databox-xxlg .databox-left {
    width: 250px;
    height: 250px
  }
  .databox.databox-xxlg .databox-right {
    -lh-property: 0;
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px);
    height: 250px
  }
  .databox.databox-xxlg.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px)
  }
  .databox.databox-xxlg.databox-inverted .databox-right {
    width: 250px
  }
  .databox.databox-vertical {
    width: 100%;
    height: 100px
  }
  .databox.databox-vertical .databox-top {
    position: relative;
    width: 100%;
    height: 50px;
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 5px;
    color: #51445f
  }
  .databox.databox-vertical .databox-top[class*="bg-"] {
    color: #fff
  }
  .databox.databox-vertical .databox-bottom {
    position: relative;
    width: 100%;
    height: 50px;
    display: inline-block;
    margin: 0;
    color: #555;
    padding: 5px 10px
  }
  .databox.databox-vertical .databox-bottom.bordered {
    border-top: 1px solid #fff
  }
  .databox.databox-vertical .databox-bottom.bordered-thick {
    border-top: 3px solid #fff
  }
  .databox.databox-vertical .databox-bottom[class*="bg-"] {
    color: #fff
  }
  .databox.databox-vertical .databox-icon>.fa:before, .databox.databox-vertical .databox-icon .typcn:before, .databox.databox-vertical .databox-icon .glyphicon:before, .databox.databox-vertical .databox-icon .wi:before {
    font-size: 38px
  }
  .databox.databox-vertical.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top .databox-row:first-child .databox-cell:first-child {
    -webkit-border-radius: 3px 0 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top .databox-row:first-child .databox-cell:last-child {
    -webkit-border-radius: 0 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top img {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom .databox-row:last-child .databox-cell:first-child {
    -webkit-border-radius: 0 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 0 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom .databox-row:last-child .databox-cell:last-child {
    -webkit-border-radius: 0 0 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom img {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.databox-graded {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(top, #e5e5e5, #fff);
    background-image: -moz-linear-gradient(top, #e5e5e5, #fff);
    background-image: -o-linear-gradient(top, #e5e5e5, #fff);
    background-image: linear-gradient(to bottom, #e5e5e5, #fff)
  }
  .databox.databox-vertical.databox-halved .databox-top {
    height: 50%
  }
  .databox.databox-vertical.databox-halved .databox-bottom {
    height: 50%
  }
  .databox.databox-vertical.databox-lg {
    height: 150px;
    width: 100%
  }
  .databox.databox-vertical.databox-lg .databox-number {
    font-size: 17px;
    margin: 2px
  }
  .databox.databox-vertical.databox-lg .databox-icon>.fa:before, .databox.databox-vertical.databox-lg .databox-icon .typcn:before, .databox.databox-vertical.databox-lg .databox-icon .glyphicon:before {
    font-size: 38px!important;
    padding: 0
  }
  .databox.databox-vertical.databox-lg .databox-icon .wi:before {
    font-size: 50px!important;
    padding: 0
  }
  .databox.databox-vertical.databox-lg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-lg .databox-bottom {
    height: 100px
  }
  .databox.databox-vertical.databox-lg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-lg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-lg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-lg.databox-inverted .databox-icon .wi:before {
    font-size: 85px;
    padding: 0
  }
  .databox.databox-vertical.databox-lg.databox-inverted .databox-top {
    height: 100px
  }
  .databox.databox-vertical.databox-lg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-lg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-lg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-lg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-lg.databox-halved .databox-icon .wi:before {
    font-size: 58px;
    padding: 0
  }
  .databox.databox-vertical.databox-lg.databox-halved .databox-top {
    height: 75px
  }
  .databox.databox-vertical.databox-lg.databox-halved .databox-bottom {
    height: 75px
  }
  .databox.databox-vertical.databox-xlg {
    height: 200px;
    width: 100%
  }
  .databox.databox-vertical.databox-xlg .databox-icon>.fa:before, .databox.databox-vertical.databox-xlg .databox-icon .typcn:before, .databox.databox-vertical.databox-xlg .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
  }
  .databox.databox-vertical.databox-xlg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-xlg .databox-bottom {
    height: 150px
  }
  .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .wi:before {
    font-size: 120px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xlg.databox-inverted .databox-top {
    height: 150px
  }
  .databox.databox-vertical.databox-xlg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-xlg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-xlg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-xlg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xlg.databox-halved .databox-icon .wi:before {
    font-size: 85px;
    padding: 0
  }
  .databox.databox-vertical.databox-xlg.databox-halved .databox-top {
    height: 100px
  }
  .databox.databox-vertical.databox-xlg.databox-halved .databox-bottom {
    height: 100px
  }
  .databox.databox-vertical.databox-xxlg {
    height: 300px;
    width: 100%
  }
  .databox.databox-vertical.databox-xxlg .databox-icon>.fa:before, .databox.databox-vertical.databox-xxlg .databox-icon .typcn:before, .databox.databox-vertical.databox-xxlg .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
  }
  .databox.databox-vertical.databox-xxlg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-xxlg .databox-bottom {
    height: 250px
  }
  .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .wi:before {
    font-size: 220px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxlg.databox-inverted .databox-top {
    height: 250px
  }
  .databox.databox-vertical.databox-xxlg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .wi:before {
    font-size: 120px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxlg.databox-halved .databox-top {
    height: 150px
  }
  .databox.databox-vertical.databox-xxlg.databox-halved .databox-bottom {
    height: 150px
  }
  .databox.databox-vertical.databox-xxxlg {
    height: 500px;
    width: 100%
  }
  .databox.databox-vertical.databox-xxxlg .databox-icon>.fa:before, .databox.databox-vertical.databox-xxxlg .databox-icon .typcn:before, .databox.databox-vertical.databox-xxxlg .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxxlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
  }
  .databox.databox-vertical.databox-xxxlg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-xxxlg .databox-bottom {
    height: 450px
  }
  .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .wi:before {
    font-size: 420px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-top {
    height: 450px
  }
  .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .wi:before {
    font-size: 220px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxxlg.databox-halved .databox-top {
    height: 250px
  }
  .databox.databox-vertical.databox-xxxlg.databox-halved .databox-bottom {
    height: 250px
  }
  .databox .chart {
    font-size: 13px
  }

  

  .salary-slip {
    margin: 15px;
  }
  .salary-slip .empDetail {
    width: 100%;
    text-align: left;
    border: 2px solid black;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .salary-slip .head {
    margin: 10px;
    margin-bottom: 50px;
    width: 100%;
  }
  .salary-slip .companyName {
    text-align: right;
    font-size: 25px;
    font-weight: bold;
  }
  .salary-slip .salaryMonth {
    text-align: center;
  }
  .salary-slip .table-border-bottom {
    border-bottom: 1px solid;
  }
  .salary-slip .table-border-right {
    border-right: 1px solid;
  }
  .salary-slip .myBackground {
    padding-top: 10px;
    text-align: left;
    border: 1px solid black;
    height: 40px;
  }
  .salary-slip .myAlign {
    text-align: center;
    border-right: 1px solid black;
  }
  .salary-slip .myTotalBackground {
    padding-top: 10px;
    text-align: left;
    background-color: #ebf1de;
    border-spacing: 0px;
  }
  .salary-slip .align-4 {
    width: 25%;
    float: left;
  }
  .salary-slip .tail {
    margin-top: 35px;
  }
  .salary-slip .align-2 {
    margin-top: 25px;
    width: 50%;
    float: left;
  }
  .salary-slip .border-center {
    text-align: center;
  }
  .salary-slip .border-center th,
  .salary-slip .border-center td {
    border: 1px solid black;
  }
  .salary-slip th,
  .salary-slip td {
    padding-left: 6px;
  }